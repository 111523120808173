@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* --primary-color: #ff5300; */
  --primary-color: #fbb528;
  --primary-color2: #354f7e;
  --primary-bg: #ffffff;
  --text: #3a3a3a;
  --text-2: #ffffff;
  --black: #000000;

}
.background-radial-gradient {
  background-color: hsl(218, 41%, 15%);
  background-image: radial-gradient(
      650px circle at 0% 0%,
      hsl(218, 41%, 35%) 15%,
      hsl(218, 41%, 30%) 35%,
      hsl(218, 41%, 20%) 75%,
      hsl(218, 41%, 19%) 80%,
      transparent 100%
    ),
    radial-gradient(
      1250px circle at 100% 100%,
      hsl(218, 41%, 45%) 5%,
      hsl(218, 41%, 30%) 15%,
      hsl(218, 41%, 20%) 35%,
      hsl(218, 41%, 19%) 50%,
      transparent 100%
    );
  position: relative;
}
#radius-shape-1 {
  height: 220px;
  width: 220px;
  top: -60px;
  left: -130px;
  background: radial-gradient(#9c6a05, var(--primary-color));
  overflow: hidden;
}

#radius-shape-2 {
  border-radius: 38% 62% 63% 37% / 70% 33% 67% 30%;
  bottom: -60px;
  right: -110px;
  width: 300px;
  height: 300px;
  background: radial-gradient(#9c6a05, var(--primary-color));
  overflow: hidden;
}

.bg-glass {
  background-color: rgba(255, 255, 255, 0.25);
  backdrop-filter: saturate(200%) blur(25px);
}

.Popular_search {
  background-color: var(--primary-bg);
  opacity: 0.65;
  padding: 5px 10px;
  font-size: small;
  border-radius: 15px;
  cursor: pointer;
  margin-right: 3px;
  font-weight: 600;
  word-break: keep-all;
  width: fit-content;
  display: inline-block;
  margin: 5px;
}

.Popular_search:hover {
  opacity: 0.95;
}

.swiper-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.swiper-button-next,
.swiper-button-prev {
  border: thin solid transparent;
  border-radius: 50%;
  padding: 30px;
  background-color: var(--primary-bg);
}
.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: large !important;
}

/* gallary */

.gallaryGrid {
  column-count: 4;
  column-gap: 1em;
  margin: 0 auto;
}
.gallaryGrid > a {
  background: transparent;
  display: inline-block;
  margin: 0 0 1em;
  width: 100%;

}


@media (max-width: 500px) {
  .gallaryGrid {
    column-count: 2;
  }

}

@media (min-width: 501px) and (max-width: 800px) {
  .gallaryGrid {
    column-count: 2;
  }
}

@media (min-width: 801px) {
  .gallaryGrid {
    column-count: 4;
  }
}
#navbar,.navList{
  background: rgb(45,68,108);
  background: -moz-linear-gradient(0deg, rgba(45,68,108,1) 20%, rgba(53,79,126,1) 80%);
  background: -webkit-linear-gradient(0deg, rgba(45,68,108,1) 20%, rgba(53,79,126,1) 80%);
  background: linear-gradient(0deg, rgba(45,68,108,1) 20%, rgba(53,79,126,1) 80%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2d446c",endColorstr="#354f7e",GradientType=1);
}
.active-scroll-spy {
  background-color: var(--primary-color2);
  color: var(--text-2); 
  padding: 2px 7px;
  /* border-radius: 15px; */
}
html {
  scroll-padding-top: 120px; /* height of your navbar */
}

input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    /* Firefox */
    -moz-appearance: textfield;
  }
.error{
  color: red;
  font-size: 12px;
  margin: 2px;
}
.error::first-letter{
  text-transform: uppercase;
}
.PolicySectionBody>ol,.PolicySectionBody>ul,.PolicySectionBody>li{
  list-style-type: auto;
  text-align: start;
  margin: 2px;
}
Link {
  cursor: pointer!important;
}

#change_text_main_window {
  font-size: 5rem;
  line-height: 1.2;
  font-weight: 600;
  height: calc(1 * 1.2 * 5rem);
  overflow: hidden;
}

#change_text {
  animation: swapTitleDesktop 8s cubic-bezier(0.22, 1, 0.36, 1) 5s infinite
    forwards;
}

#change_text > ol > li {
  color: var(--primary-color);
}

@media (max-width: 430px) {
  #change_text_main_window {
    font-size: 3rem;
    line-height: 1.2;
    font-weight: 600;
    height: calc(1 * 1.2 * 3rem);
    overflow: hidden;
  }

  #change_text {
    animation: swapTitleMobile 8s cubic-bezier(0.22, 1, 0.36, 1) 5s infinite
      forwards;
  }
}
@keyframes swapTitleMobile {
  0% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(-60px);
  }

  50% {
    transform: translateY(-120px);
  }

  75% {
    transform: translateY(-180px);
  }

  100% {
    transform: translateY(-240px);
  }
}

@keyframes swapTitleDesktop {
  0% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(-100px);
  }

  50% {
    transform: translateY(-200px);
  }

  75% {
    transform: translateY(-300px);
  }

  100% {
    transform: translateY(-400px);
  }
}


